.footer {
  background-color: $brand-color;
  color: $white-color;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;

  @media (min-width: 768px) {
    &::after {
      content: '';
      display: block;
      width: 220px;
      height: 220px;
      background-color: $white-color;
      border-radius: 100%;
      position: absolute;
      right: -110px;
      bottom: -110px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;
    color: $white-color;
  }

  a {
    color: inherit;
  }
}

.footer__secondary {
  text-align: center;
  border-top: 1px solid rgba($white-color, 0.2);
  font-size: 14px;
  margin-top: 20px;
  padding-top: 20px;
}

.social{
  .fa{
    margin-right: 5px;
  }
}


@media (min-width: 768px) {
  .social{
    text-align: right;
  }
}