.highlight {
  background: $brand-color;
  color: $white-color;
  border-radius: 10px;
  position: relative;
  .tile__badge{
    background: $white-color;
    color: $brand-color;
    left: -1px; top: -1px;
  }
}

.highlight--default {
  .highlight__body {

    padding: 10px 20px 20px 20px;
    font-size: 14px;
  }

  .highlight-title {
    font-size: 20px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.highlight--large {
  text-align: center;

  .highlight__body {
    background-color: $brand-color;
    padding: 20px;
    font-size: 16px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    p{
      line-height: 1.7;
    }
  }

  .highlight-title {
    font-size: 40px;
    line-height: 60px;
    margin-top: 0;
    margin-bottom: 0px;
  }


}

.highlight__image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.highlight__body {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}




.subscribe{
  margin: auto;
  position: relative;
  input{
    font-size: 1.2em;
    padding: 16px;
    padding-top: 10px;
    width: 100%;
    margin: 0;
    background: $white-color;
    height: 50px;
    border: 2px solid $dark-brand-color;
    &[type="submit"]{
      background: $brand-color;
      color: $white-color;
      border-top: 0;
      vertical-align: bottom;
      text-align: center;
    }
  }
}


div.wpcf7-mail-sent-ok, div.wpcf7-validation-errors{
  background-color: $dark-brand-color;
  border: none;
  max-width: 79%;
  margin: 20px auto;
  margin-bottom: 0;
}

div.wpcf7 img.ajax-loader{
    position: absolute;
    right: -25px;
    top: 19px;
    content:url("../images/ellipsis.svg");
}

.use-floating-validation-tip span.wpcf7-not-valid-tip{
  background: #ff0000;
  color: #fff;
  top: -11px;
  right: 2px;
  left: auto;
  border: none;
}
div.wpcf7-validation-errors{
  background-color: #ec9c1d;
}

@media (min-width: 768px) {

  .highlight--large{
    .highlight__body{
      padding: 50px;
      padding-top: 40px;
    }
  }

}

@media (min-width: 992px) {

  .home{
      .subscribe{
        width: 90%;
        input{
          width: 65%;
          &[type="submit"]{
            width: 35%;
            border-top: 2px solid $dark-brand-color;
            border-left: 0;
          }
        }
      }
  }

}

@media (min-width: 1200px) {


  .home{
      .subscribe{
        width: 80%;
      }
  }

  .subscribe{
    input{
      width: 65%;
      &[type="submit"]{
        width: 35%;
        border-top: 2px solid $dark-brand-color;
        border-left: 0;
      }
    }
  }

}

