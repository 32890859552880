.banner {
  @media (min-width: 1280px) {
    position: relative;

    &::before {
      content: '';
      display: block;
      background-color: $brand-color;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      z-index: 10;
      width: 120px;
      height: 120px;
      left: -60px;
      margin-top: -8px;
    }
  }

  @media (min-width: 1400px) {
    &::before {
      width: 222px;
      height: 222px;
      left: -111px;
      margin-top: -56px;
    }
  }
}

.banner__main {
  padding-top: 60px;
}


@media (min-width: 992px) {

  .banner__main {
    padding-top: 110px;
  }

}