$enable-flex: false;

// Colors
$white-color: 			#fff;
$black-color: 			#000;
$base-color: 			#222626;
$brand-color: 			#007178;
$light-brand-color: 	#7fb8bb;
$dark-brand-color: 		#005a5e;
$light-grey-color: 		#d3d4d4;
$grey-color: 			#414444;



$medium-font-family: 'branding-medium', sans-serif;
$semibold-font-family: 'branding-semi-bold', sans-serif;
$bold-font-family: 'branding-bold', sans-serif;

// Bootstrap
$font-family-base: $medium-font-family;
$line-height-base: 1.25;
$body-color: $base-color;
