@font-face {
  font-family: 'branding-medium';
  src: url('../fonts/Branding-Medium.woff2') format('woff2'),
       url('../fonts/Branding-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'branding-semi-bold';
  src: url('../fonts/Branding-SemiBold.woff2') format('woff2'),
       url('../fonts/Branding-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'branding-bold';
  src: url('../fonts/Branding-SemiBold.woff2') format('woff2'),
       url('../fonts/Branding-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-family: $bold-font-family;
}

a {
  color: $brand-color;
  text-decoration: underline;
  word-wrap: break-word;


  &:hover {
    color: shade($brand-color, 20%);
  }
}

img{
  max-width: 100%;
}

body{
  line-height: 1.4;
}

#map-canvas{
  height: 340px;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba($black-color, 0.5);
}

ul{
  padding-left: 17px;
}