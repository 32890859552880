.tile {
  position: relative;
  margin-top: 20px;
}

.tile__badge {
  position: absolute;
  left: 0;
  top: 0;
  background-color: $brand-color;
  color: $white-color;
  font-family: $medium-font-family;
  font-size: 20px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 20px;
}

.tile__image {
  border-radius: 10px;
}

.tile__body {
  padding: 20px 20px 16px 20px;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.tile__actions {
  border-top: 1px solid $light-grey-color;
  padding: 14px 20px 20px 20px;
}

.tile__title {
  font-size: 20px;
  line-height: 30px;
  color: $brand-color;
  margin-top: 0;
  margin-bottom: 6px;
  line-height: 1;
  a{
    text-decoration: none;
  }
}

@media (max-width: 1200px) {
  .news-img{
    padding-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .news-img{
    padding: 10px 0 20px 0;
  }
}

@media (min-width: 768px) {

  .tile{
    margin-top: 0;
  }

}