.footer-list {
  margin: 0;
  padding: 0;
  display: inline-block;

  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;

    &::before {
      content:"\2022";
      display: inline-block;
      margin-right: 4px;
    }
  }
}
