.well {
  border-radius: 10px;
  padding: 30px 40px 38px 40px;
  box-shadow: 0 0 5px 0 rgba($black-color, 0.5);
  margin-top: 20px;
}

.well__title {
  font-size: 22px;
  line-height: 30px;
  color: $brand-color;
  margin-top: 0;
  margin-bottom: 6px;
  position: relative;
  padding-left: 38px;

  span {
    display: block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 100%;
    background-color: $brand-color;
    color: $white-color;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
  }
  a{
    text-decoration: none;
  }
}

.well__body {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ul {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 10px 0 10px 22px;
      border-bottom: 1px solid rgba($brand-color, 0.3);
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background-color: $brand-color;
        border-radius: 100%;
        position: absolute;
        left: 5px;
        top: 17px;
      }

      &:first-child {
        border-top: 1px solid rgba($brand-color, 0.3);
      }
    }
  }
}


@media (min-width: 768px) {

  .well{
    margin-top: 0;
    margin-bottom: 20px;
  }
  }
