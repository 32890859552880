.nav-primary {
  text-align: right;
}

.nav-primary__list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0; // inline-block fix

  li {
    margin: 0;
    padding: 0;
    display: inline-block;
    border-right: 1px solid $light-brand-color;
    border-top: 1px solid $light-brand-color;
    border-bottom: 1px solid $light-brand-color;
    font-size: 13px;
    width: 20%;

    &:first-child {
      border-left: 1px solid $light-brand-color;
    }

    &.active a {
      background-color: $brand-color;
      color: #fff;
    }
  }

  a {
    display: block;
    padding: 12px 6px 10px 6px;
    text-decoration: none;
    color: $brand-color;
    font-family: $semibold-font-family;
    text-align: center;
  }
}

@media (min-width: 375px) {
  .nav-primary__list{

    li{
      font-size: 13px;
    }
    a{
     // padding: 12px 10px 10px 10px;
    }
  }
}

@media (min-width: 515px) {
  .nav-primary__list{

    li{
      font-size: 16px;

    }
  }
}


@media (min-width: 992px) {


  .nav-primary__list{

    li{
      font-size: 18px;
      width: auto;
    }
    a{
      padding: 12px 10px 10px 10px;
    }
  }

}