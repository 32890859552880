.news-widget-meta {
  margin-bottom: 10px;
  .news-widget-meta__date{
  	text-transform: uppercase;
  }
}


.post{

	h1.entry-title{
		color: $brand-color;
		font-size: 2.8em;
	}
	h3.enty-title{
		
		a{
			text-decoration: none;
		}
	}
}

@media (min-width: 768px) {
	.post{

		h1.entry-title{
			font-size: 3.5em;
		}
	}


}