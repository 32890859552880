.hero {
  height: 340px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 40px;
  &:before{
    content: ' ';
    display: block;
    background: rgba(255,255,255,0.3);
    position: absolute;
    top: 0;
    bottom: 0; 
    left: 0;
    right: 0;
  }
}

.hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  


  color: $brand-color;
  font-family: $bold-font-family;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  //text-shadow: 0px 0px 1px rgba(255,255,255,0.2);
}

@media (min-width: 768px) {
  .hero__title {
    font-size: 55px;
  }
}