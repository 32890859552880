.page-title {
  font-size: 40px;
  //line-height: 60px;
  margin-top: 25px;
  margin-bottom: 10px;
  color: $base-color;
}


@media (min-width: 768px) {

	.page-title{
		margin-bottom: 30px;
	}

}	