.brand {
  display: inline-block;
  padding-top: 6px;
  padding-bottom: 26px;
}

@media (min-width: 992px) {

	.brand {
	  padding-bottom: 6px;
	}

}
